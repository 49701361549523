export function similarProductsSheetTemplate() {
  return `
<oc-sheet-v1 hide-header no-content-padding full-height id="reptile_similar-products-sheet" class="reptile_similar-product-sheet" oc-aria-label="Ähnliche Artikel" style="display: none">
  <div class="reptile_similar-products-sheet_wrapper">
    <div class="reptile_similar-products-sheet_selectedContent">
      <div class="reptile_similar-products-sheet_imageContainer">
        <picture>
          <source id="reptile_similar-products-sheet_imageSourceWebp" type="image/webp"/>
          <source id="reptile_similar-products-sheet_imageSourceJpeg" type="image/jpeg"/>
          <img id="reptile_similar-products-sheet_image"/>
        </picture>
        <oc-button-v1 id="reptile_similar-products-sheet_pdp_button" variant="primary" size="50" icon-type-right="leaving-page">Zum Produkt</oc-button-v1>
      </div>
    </div>
    <div class="reptile_similar-container">
      <h3 class="oc-headline-100">Mehr davon</h3>
      <oc-banner-v1 class="reptile_similar-error" size="100" variant="error">
        <span>Wir bitten um Entschuldigung. Das hat leider nicht funktioniert. Bitte versuche es später erneut.</span>
      </oc-banner-v1>
      <div id="reptile_similar-tilelist">
        <section class="reptile_tilelist"></section>
      </div>
    </div>
  </div>
</oc-sheet-v1>
  `;
}
