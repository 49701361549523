import { eventQBus } from "../types/EventQBus";
import { findMatch } from "../utils/Dom";
import { event } from "@otto-ec/global-resources/event";

let parentContainer: HTMLElement | null;

const SEARCH_RESULT = "reptile-search-result";
const SIMILAR_PRODUCT_SHEET = "reptile_similar-products-sheet";

export function resolveLinkType(elem: HTMLElement, event: MouseEvent) {
  const target = event.target as HTMLElement;

  if (target && target.closest(".find_tile__sustainable")) {
    return "sustainability_badge";
  }
  if (target && target.closest(".find_tile__discount")) {
    return "discount";
  }
  if (target && target.closest(".find_tile__discountBenefit")) {
    return "kuvo";
  }
  if (target && target.closest(".reptile_colorThumbLink")) {
    return "color_tiles";
  }
  if (target && target.closest(".reptile_tile__rating")) {
    return "rating";
  }
  if (target && target.closest(".reptile_tile__expertReviews--short")) {
    return "test";
  }
  if (target && target.closest(".reptile_tile__priceLink")) {
    return "price";
  }
  if (elem.classList.contains("find_tile__productLink")) {
    return "text";
  }
  if (elem.classList.contains("find_tile__productImageLink")) {
    return "image";
  }
  return "";
}

function track(productContainer: HTMLElement, linkType: string) {
  let trackingString = productContainer.getAttribute("data-producttile-tracking");
  if (trackingString != null) {
    trackingString = atob(trackingString).replace("#linkType#", linkType);
    eventQBus.emit("tracking.bct.trackOnNextPageImpression", JSON.parse(trackingString));
  }
}

function handleClick(this: Element, ev: MouseEvent) {
  /*                            */
  let linkType;
  const productContainer = findMatch(
    this as HTMLElement,
    function (element) {
      return element.classList.contains("product");
    },
    parentContainer,
  );

  if (productContainer) {
    linkType = resolveLinkType(this as HTMLElement, ev);
    if (linkType !== "" && !ev.ctrlKey) {
      track(productContainer as HTMLElement, linkType);
    }
  }
}

function onTileListLoaded(selector: string) {
  parentContainer = document.getElementById(selector);
  if (parentContainer == null) {
    parentContainer = document.querySelectorAll<HTMLElement>(".reptile_qr-page")[0];
  }
  if (parentContainer) {
    event.delegate(parentContainer, "click", ".product a", handleClick);
  }
}

export function registerProductTileClickHandling() {
  eventQBus.on("ftfind.tilelist.loaded", () => onTileListLoaded(SEARCH_RESULT));
  eventQBus.on("reptile.similar_product_sheet.loaded", () => onTileListLoaded(SIMILAR_PRODUCT_SHEET));
  onTileListLoaded(SEARCH_RESULT);
}
