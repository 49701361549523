import { eventQBus } from "../types/EventQBus";
import { OnSimilarProductSheetOpen } from "../types/Tile";
import { sheetV1 } from "@otto-ec/otto-components/sheet";
import { core } from "@otto-ec/global-resources/core";
import { similarProductsSheetTemplate } from "./SimilarProductsSheetTemplate";

enum SELECTOR {
  IMAGE_WEBP = "reptile_similar-products-sheet_imageSourceWebp",
  IMAGE_JPEG = "reptile_similar-products-sheet_imageSourceJpeg",
  IMAGE = "reptile_similar-products-sheet_image",
  PDP_BUTTON = "reptile_similar-products-sheet_pdp_button",
  SHEET = "reptile_similar-products-sheet",
  IMAGE_CONTAINER = ".reptile_similar-products-sheet_imageContainer",
  SIMILAR_PRODUCTS_LIST = ".reptile_similar-container .reptile_tilelist",
  SIMILAR_PRODUCTS_ERROR = ".reptile_similar-error",
}

class SimilarProductsSheet {
  private constructor(
    private title: string,
    private price: string,
    private imageSourceWebpUrl: string,
    private imageSourceJpegUrl: string,
    private imageUrl: string,
    private imageAltTag: string,
    private adsLink: string,
    private variationId: string,
  ) {
    this.imageSourceWebp?.setAttribute("srcset", this.imageSourceWebpUrl);
    this.imageSourceJpeg?.setAttribute("srcset", this.imageSourceJpegUrl);
    this.image?.setAttribute("src", this.imageUrl);
    this.image?.setAttribute("alt", this.imageAltTag);
    if (!!this.image) {
      this.image.onload = (e) => {
        const height = this.image?.naturalHeight ?? 0;
        const width = this.image?.naturalWidth ?? 0;
        if (height >= width) {
          this.imageContainer?.style.setProperty("aspect-ratio", "1 / 1");
          (e.target as HTMLImageElement).style.height = "100%";
        } else {
          (e.target as HTMLImageElement).style.width = "100%";
          this.imageContainer?.style.setProperty(
            "aspect-ratio",
            `${this.image?.naturalWidth} / ${this.image?.naturalHeight}`,
          );
        }
      };
    }
    this.pdpButton?.setAttribute("href", this.adsLink);

    this.loadSimilarProducts(this.variationId);
  }

  get imageSourceWebp(): HTMLSourceElement | null {
    return document.getElementById(SELECTOR.IMAGE_WEBP) as HTMLSourceElement | null;
  }

  get imageSourceJpeg(): HTMLSourceElement | null {
    return document.getElementById(SELECTOR.IMAGE_JPEG) as HTMLSourceElement | null;
  }

  get image(): HTMLImageElement | null {
    return document.getElementById(SELECTOR.IMAGE) as HTMLImageElement | null;
  }

  get pdpButton(): HTMLElement | null {
    return document.getElementById(SELECTOR.PDP_BUTTON) as HTMLElement | null;
  }

  get sheet(): HTMLElement | null {
    return document.getElementById(SELECTOR.SHEET) as HTMLElement | null;
  }

  get imageContainer(): HTMLElement | null {
    return document.querySelector(SELECTOR.IMAGE_CONTAINER) as HTMLElement | null;
  }

  get similarProductsList(): HTMLElement | null {
    return document.querySelector(SELECTOR.SIMILAR_PRODUCTS_LIST) as HTMLElement | null;
  }

  get similarProductsError(): HTMLElement | null {
    return document.querySelector(SELECTOR.SIMILAR_PRODUCTS_ERROR) as HTMLElement | null;
  }

  get imageSize(): string {
    const height = this.image?.height ?? 0;
    const width = this.image?.naturalWidth ?? 0;
    const size = height >= width ? width : height;
    return `${size}px`;
  }

  private loadSimilarProducts(variationId: string): void {
    this.toggleErrorMessage(false);
    this.clearSimilarProducts();

    const query = core.serialize({
      dreson: "(und.(suchbegriff.hosen).(~.(v.1)))", /*                           */
      variationIds: variationId,
    });

    fetch(`/everglades/products/semantic?${query}`)
      .then(this.extractHtmlFragment)
      .then(this.replaceSimilarProducts.bind(this))
      .then(() => eventQBus.emit("reptile.similar_product_sheet.loaded"))
      .catch(this.toggleErrorMessage.bind(this));
  }

  private extractHtmlFragment(response: Response) {
    if (response.status !== 200) {
      throw Error(`Failed to retrieve similar products: ${response.status} ${response.statusText}`);
    }
    return response.text();
  }

  private replaceSimilarProducts(html: string) {
    const range = document.createRange();
    const referenceNode = this.similarProductsList;
    if (referenceNode) {
      range.selectNode(referenceNode);
      const fragment = range.createContextualFragment(html);
      range.deleteContents();
      range.insertNode(fragment);
    }
  }

  private clearSimilarProducts() {
    const dummySkeleton = document.getElementById("reptile_dummy_skeleton");
    if (dummySkeleton) {
      document
        .querySelectorAll(SELECTOR.SIMILAR_PRODUCTS_LIST + " .find_tile")
        .forEach((target) => (target.innerHTML = dummySkeleton.innerHTML));
    } else {
      const referenceNode = this.similarProductsList;
      if (referenceNode) referenceNode.innerHTML = "";
    }
  }

  private toggleErrorMessage(show: boolean) {
    const banner = this.similarProductsError;
    if (banner) {
      banner.style.display = show ? "block" : "none";
    }
  }

  public open() {
    sheetV1.open(SELECTOR.SHEET);
  }

  static createSheetMarkupIfNotExist() {
    const sheet = document.getElementById(SELECTOR.SHEET) as HTMLElement;
    if (sheet != null) return;
    const sheetPlaceholder: HTMLElement = document.getElementById(
      "reptile_similar-products-sheet-placeholder",
    ) as HTMLElement;
    if (sheetPlaceholder == null) return;
    const similarListClassList = sheetPlaceholder.classList;
    sheetPlaceholder.outerHTML = similarProductsSheetTemplate();
    document.getElementById("reptile_similar-tilelist")?.classList.add(...similarListClassList);
  }

  static create(data: OnSimilarProductSheetOpen) {
    if (!data) return null;
    return new SimilarProductsSheet(
      data.title,
      data.price,
      data.imageSourceWebpUrl,
      data.imageSourceJpegUrl,
      data.imageUrl,
      data.imageAltTag,
      data.adsLink,
      data.variationId,
    );
  }
}

/**
 *
 *
 *
 *
 *
 */
export function registerSimilarProductSheet() {
  eventQBus.on("reptile.tile.similarProductsOpen", (data: OnSimilarProductSheetOpen) => {
    SimilarProductsSheet.createSheetMarkupIfNotExist();
    setTimeout(() => SimilarProductsSheet.create(data)?.open(), 0);
  });
}
