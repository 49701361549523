import registerTilelistLoadingEvent from "./tilelist/TilelistLoading";
import { registerTilelistTracking } from "./tracking/TilelistTracking";
import { registerTileLoading } from "./tilelist/TileLoading";
import { registerColorChange } from "./tilelist/ColorChange";
import "./navigation/InvalidPageRedirect";
import { registerFirebase } from "./tracking/Firebase";
import { eventQBus } from "./types/EventQBus";
import { invokePreloadPolyfill } from "./utils/Dom";
import { addSortEventListenerToElements } from "./tilelist/Sort";
import { initComparisonAndSheet } from "./comparison/Comparison";
import { registerTileScrolling } from "./tilelist/ScrollTracking";
import { registerInvalidPageRedirect } from "./navigation/InvalidPageRedirect";
import { registerProductTileClickHandling } from "./tilelist/ProductTileClickHandling";
import { registerPaging } from "./tilelist/Paging";
import { registerTileListHistory } from "./tilelist/TilelistHistory";
import { registerExacTag } from "./tilelist/ExacTag";
import { registerTilelistCache } from "./tilelist/TilelistCache";
import { eventLoader } from "@otto-ec/global-resources/event-loader";
import { registerProductComparisonBanner, registerProductComparisonCookieCheck, } from "./tilelist/ProductComparisonBanner";
import NewsSlider from "./tilelist/slider/NewsSlider";
import RatingSlider from "./tilelist/slider/RatingSlider";
import { registerSponsored } from "./sponsored/SponsoredSheetHandler";
import { registerDiscountBenefitSheet } from "./tilelist/DiscountBenefitHandler";
import { registerSimilarProductSheet } from './similar/SimilarProductsSheetHandler';

eventLoader.onReady(2, () => {
  const newSlider = new NewsSlider();
  const ratingSlider = new RatingSlider();

  registerTileListHistory();
  registerTilelistTracking();
  registerTilelistLoadingEvent();
  addSortEventListenerToElements();
  registerPaging();
  registerTilelistCache(undefined);
  newSlider.registerSlider();
  ratingSlider.registerSlider();

  eventQBus.on("ftfind.tilelist.loaded", addSortEventListenerToElements);
  /*                       */
  eventQBus.on("ftfind.tilelist.loaded", invokePreloadPolyfill);
  eventQBus.on("ftfind.tilelist.loaded", registerTilelistCache);
});

eventLoader.onReady(9, () => {
  initComparisonAndSheet();
  registerDiscountBenefitSheet();
  registerFirebase();
  registerExacTag();
  registerSimilarProductSheet();
});

eventLoader.onReady(11, () => {
  registerProductTileClickHandling();
  registerSponsored();
  registerProductComparisonCookieCheck();
  registerProductComparisonBanner();
});

eventLoader.onReady(21, () => {
  registerColorChange();
});

eventLoader.onReady(60, () => {
  registerInvalidPageRedirect();
});

eventLoader.onReady(99, () => {
  registerTileLoading();
  registerTileScrolling();
});
